module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@4.22.0_gatsby@2.32.13_@types+node@22.7.4_@types+react@16.14.26_@typ_4lwcpbihy3sp2c7nsxdjv36kxy/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-XTZ68FJHLS","UA-239120813-1"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-netlify-cms@4.3.9_gatsby@2.32.13_@types+node@22.7.4_@types+react@16.14.26_@type_k2i5fsy5eeufecc7mwihuxchce/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-hubspot@1.3.4_gatsby@2.32.13_@types+node@22.7.4_@types+react@16.14.26_@types+we_gmhbuwgxpow75opxv4zaozhelq/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7040627","respectDNT":true,"productionOnly":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-remark-images@3.5.0_gatsby-plugin-sharp@2.6.9_gatsby@2.32.13_@types+node@22.7.4_@types_ou5u6s4ik7lsh4gf2rxlw6b7pe/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@2.4.9_gatsby@2.32.13_@types+node@22.7.4_@types+react@16.14.26_@types+w_jx4xhpcyk3eguncp6ki4znztpy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/_img/logo-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"05da2373672bb6d913d365c672ac19a9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
